<template>
  <div>
    User Account Verification: {{states.verification}}
    <!-- <v-btn @click="verifyURL">verifyURL</v-btn>
    <v-btn @click="verified">verified</v-btn> -->
    
    <v-card width="600" v-if="states.verification!='idle'">
        <v-card-text>
            Account Verficiation Status: {{states.verification}}
            <hr>
            Message: {{message}}
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <router-link v-if="states.verification!='success'" :to="'/'">
                <v-btn>Home</v-btn>
            </router-link>
            <router-link v-else :to="'/sign_in'">
                <v-btn>Login</v-btn>
            </router-link>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    
    <v-dialog width="150" v-model="ui.busy" persistent>
        <v-card>
            <v-card-text>
                Verifying Account
            </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import awsconfig from './config.js';
Amplify.configure({
    Auth: awsconfig.Auth
});
export default {
    created: function(){
        this.verifyURL()
    },
    data: function(){
        return {
            ui: {
                busy: false
            },
            config: {
                verificationServer: 'https://api.thrombosiscanada.ca',
                pinLength: 6
            },
            username: null,
            user_id: null,
            verificationCode: null,
            states: {
                verification: 'idle'
            },
            message: null
        }
    },
    methods: {
        verifyURL: async function(){
            this.ui.busy = true
            this.states.verification = 'busy'
            let server = this.config.verificationServer
            let code = this.code
            let response
            try {
                response = await this.sendRequest('get',`/api/verification/${code}`)
                if(response.data){
                    this.username = response.data.email
                    this.user_id = response.data.user_id
                    this.verificationCode = response.data.code.toString()                    
                    await this.confirmSignUp()
                }else{
                    this.states.verification = 'invalid_link'
                }
            } catch (err) {
                console.error(err)
                this.states.verification = 'invalid_link'
            }
            this.ui.busy = false

        },
        async confirmSignUp(){
            let username = this.username
            let verificationCode = this.verificationCode
            try {
                let result = await Auth.confirmSignUp(username, verificationCode);
                await this.verified()
                console.log('confirm signUp: success',result)
                this.states.verification = 'success'
            } catch (err) {
                console.error('confirm signUp: error',err)
                this.states.verification = 'failed'
                this.message = err.message
            }
        },
        async verified(){
            let server = this.config.verificationServer
            let response
            try {
                response = await this.sendRequest('put', `/api/verification/${this.user_id}`)
            } catch (err) {
                console.error(err)
            }
        }
    },
    computed: {
        code: function(){
            return this.$route.params.code
        }
    }
}
</script>

<style>

</style>